"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEGENDS = void 0;
const BoardName_1 = require("@/common/boards/BoardName");
exports.LEGENDS = {
    [BoardName_1.BoardName.THARSIS]: [],
    [BoardName_1.BoardName.HELLAS]: [],
    [BoardName_1.BoardName.ELYSIUM]: [],
    [BoardName_1.BoardName.ARABIA_TERRA]: [],
    [BoardName_1.BoardName.UTOPIA_PLANITIA]: [],
    [BoardName_1.BoardName.VASTITAS_BOREALIS_NOVUS]: [],
    [BoardName_1.BoardName.VASTITAS_BOREALIS]: [],
    [BoardName_1.BoardName.AMAZONIS]: [],
    [BoardName_1.BoardName.TERRA_CIMMERIA]: [],
    [BoardName_1.BoardName.TERRA_CIMMERIA_NOVUS]: [
        { text: ['Albor', 'Tholius'], position: [282, 83], line: { from: [28, 18], to: [42, 27] } },
        { text: ['MSL', 'Curiosity'], position: [120, 185], line: { from: [30, 0], to: [148, 10] }, secondRowX: -15 },
        { text: ['Tyrrhenus', 'Mons'], position: [85, 225], line: { from: [40, 5], to: [65, 10] } },
        { text: ['Apollinaris', 'Mons'], position: [500, 205], line: { from: [-3, 6], to: [-100, 28] } },
        { text: ['Hadriacus', 'Mons'], position: [90, 365], line: { from: [48, -7], to: [63, -18] } },
    ],
};
